<template>
  <div>
    <div class="input_info">
      <p class="input_text">组标题：</p>
      <el-input
        v-model="requestParams.title"
        placeholder="请输入组标题"
        size="small"
        clearable
      />
    </div>

    <div style="margin: 0 0 10px 10px">
      <el-row>
        <el-button
          type="primary"
          plain
          icon="el-icon-plus"
          @click="dialogVisible = true"
          >添加</el-button
        >
      </el-row>
    </div>

    <div class="permissions">
      <el-dialog
        ref="tree"
        title="修改权限"
        center
        :visible.sync="dialogFormVisible"
        width="600px"
        @close="handleEditDialogClose"
        :before-close="handleClose"
      >
        <div style="height: 500px; overflow: auto">
          <el-tree
            :data="authData"
            :props="{ label: 'title', children: 'sub' }"
            :default-checked-keys="hasAuthNum"
            show-checkbox
            node-key="id"
            :default-expand-all="true"
            ref="selRulesTree"
          >
          </el-tree>
        </div>

        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button
            v-loading="isLoading.table"
            type="primary"
            @click="handleConfirmEditAuth"
            >确定修改
          </el-button>
        </div>
      </el-dialog>
    </div>

    <div class="table_style">
      <el-table
        ref="multipleTable"
        v-loading="isLoading.table"
        header-align="center"
        border
        class="main-table"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column prop="id" label="ID" align="center" />
        <el-table-column prop="title" label="组标题" align="center" />
        <el-table-column prop="rules" label="权限Id" align="center" />
        <el-table-column prop="created_at" label="添加时间" align="center">
          <template slot-scope="{ row }">
            <span>{{
              row.created_at
                ? new Date(row.created_at * 1000).toLocaleString("zh-Hans-CN")
                : "-"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="updated_at" label="更新时间" align="center">
          <template slot-scope="{ row }">
            <span>{{
              row.updated_at
                ? new Date(row.updated_at * 1000).toLocaleString("zh-Hans-CN")
                : "-"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="operation"
          label="操作"
          align="center"
          width="300"
        >
          <template slot-scope="{ row, $index }">
            <div>
              <el-button
                type="primary"
                size="small"
                plain
                icon="el-icon-folder-checked"
                style="margin-right: 10px"
                @click.stop="handleOpenAuthDialog(row)"
                >权限</el-button
              >
              <el-popover trigger="click" placement="top">
                <p>
                  你确认要删除角色组吗? <strong>{{ row.title }}</strong>
                </p>
                <div style="text-align: right; margin: 0">
                  <el-button size="mini" type="text" @click="hidePopover()"
                    >取消</el-button
                  >
                  <el-button
                    type="primary"
                    size="mini"
                    @click="handleDeleteAuth(row.id, $index)"
                    >确认
                  </el-button>
                </div>
                <el-button
                  slot="reference"
                  plain
                  icon="el-icon-delete"
                  type="danger"
                  size="small"
                  @click.stop
                  >删除</el-button
                >
              </el-popover>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="add_dialog">
      <el-dialog
        title="添加角色组"
        :visible.sync="dialogVisible"
        width="600px"
        @close="handleDialogClose"
        :before-close="handleClose"
        center
      >
        <div class="addAdmin">
          <p class="addAdmin_CharacterSet">组标题：</p>
          <div class="addAdmin_select">
            <el-input
              v-model="form.title"
              placeholder="请输入组标题"
              clearable
            />
          </div>
        </div>

        <div class="addAdmin">
          <p class="addAdmin_CharacterSet">权限Id：</p>
          <div class="addAdmin_select">
            <el-input
              v-model="form.rules"
              placeholder="请输入权限Id"
              clearable
            />
          </div>
        </div>

        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button
            v-loading="isLoading.table"
            type="primary"
            @click="handleDialogConfirm"
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </div>

    <div ref="footer" class="footer">
      <el-pagination
        :key="reloadPaginate"
        :current-page="pagination.page"
        :page-size="pagination.pageSize"
        :page-sizes="[10, 20, 50, 100, 200]"
        layout="total,sizes, prev, pager, next, jumper"
        :total="pagination.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        background
      />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      requestParams: {
        title: "",
      },
      form: {
        id: "",
        title: "",
        rules: "",
      },
      tableData: [],
      isLoading: {
        table: false,
      },
      reloadPaginate: 0,
      pagination: {
        total: 0,
        page: 1,
        pageSize: 10,
      },
      dialogVisible: false,
      dialogFormVisible: false,
      authData: [],
      pidsData:[],//所有一级权限ID
      hasAuthNum: [], // 拥有的权限
    };
  },
  watch: {
    "requestParams.title"() {
      this.getTableData();
    },
  },
  created() {
    this.getTableData();
    this.getRulesData();
  },
  methods: {
    // 分页跳转
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.getTableData();
    },
    handleCurrentChange(val) {
      this.pagination.page = val;
      this.getTableData();
    },
    //弹窗关闭
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done(_);
        })
        .catch((_) => {
          _;
        });
    },
    //获取到管理员列表
    getTableData() {
      this.tableData = [];
      this.pagination.total = 0;
      this.$axios
        .get("/a1/ag", {
          params: {
            title: this.requestParams.title,
            pageSize: this.pagination.pageSize,
            page: this.pagination.page,
          },
        })
        .then((res) => {
          const { data } = res.data;
          this.pagination.total = data.total;
          this.tableData = data.data;
          this.reloadPaginate++;
        });
    },
    // 深拷贝
    handleEditAuth(row) {
      const formData = JSON.parse(JSON.stringify(row));
      this.form.title = formData.title;
      this.form.rules = formData.rules;
      this.form.id = formData.id;
      this.dialogVisible = true;
    },
    //获取到权限列表
    getRulesData() {
      this.authData = [];
      this.pidsData = []
      this.$axios
        .get("/a1/rules/tree", {})
        .then((res) => {
          const { data } = res.data;
          this.authData = data;
          for (const key in this.authData) {
            if (this.authData[key].fid === 0) {
              this.pidsData.push(this.authData[key].id)
            }
          }
        })
        .catch(() => {});
    },
    // 编辑跟新增
    handleDialogConfirm() {
      this.isLoading.table = true;
      // if (this.form.id) {
      // 	this.$axios
      // 		.put(`/a1/ag/${this.form.id}`, this.form)
      // 		.then(() => {
      // 			this.$message.success("修改成功");
      // 			this.getTableData();
      // 			this.dialogVisible = false;
      // 		})
      // 		.finally(() => {
      // 			this.isLoading.table = false;
      // 		});
      // 	return;
      // }
      this.$axios
        .post("/a1/ag", this.form)
        .then(() => {
          this.$message.success("新增成功");
          this.getTableData();
          this.dialogVisible = false;
        })
        .finally(() => {
          this.isLoading.table = false;
        });
    },
    // 清空表格数据
    handleDialogClose() {
      this.form = {
        title: "",
        rules: "",
      };
    },
    // 删除
    handleDeleteAuth(id, index) {
      this.isLoading.table = true;
      this.$axios
        .delete(`/a1/ag/${id}`)
        .then(() => {
          this.tableData.splice(index, 1);
          this.$notify({
            title: "成功",
            message: "删除角色组",
            type: "success",
          });
        })
        .catch(() => {
          this.$notify.error({
            title: "失败",
            message: "删除角色组",
          });
        })
        .finally(() => {
          this.hidePopover();
          this.isLoading.table = false;
        });
    },
    //打开权限列表
    handleOpenAuthDialog(row) {
      this.dialogFormVisible = true;
      this.form.id = row.id;
      this.form.title = row.title;
      let newIds = []
      let defaultIds = row.rules.split(",") 
      for (const key in defaultIds) {
        if (this.pidsData.indexOf(parseInt(defaultIds[key]))<0) {
          newIds.push(parseInt(defaultIds[key]))
        }
      }
      this.$nextTick(() => {
	      this.$refs.selRulesTree.setCheckedKeys(newIds);
      });
    },
    //修改权限
    handleConfirmEditAuth() {
      let selArr = this.$refs.selRulesTree.getCheckedNodes();
      console.log(selArr)
      let ruls = [];
      for (let i = 0; i < selArr.length; i++) {
        if (selArr[i].fid!==0 && ruls.indexOf(selArr[i].fid)<0) {
          ruls.push(selArr[i].fid)
        }
        ruls.push(selArr[i].id);
      }

      if (this.form.id > 0) {
        this.$axios
          .put("/a1/ag/" + this.form.id, {
            title: this.form.title,
            rules: ruls.join(",") + "",
          })
          .then((res) => {
            this.$message.success("修改成功");
            this.getTableData();
            this.dialogFormVisible = false
          });
      } else {
        this.$message.error("修改出错");
      }
    },
    //关闭修改权限
    handleEditDialogClose() {
      //this.authData = [];
    },
    hidePopover() {
      this.$refs.footer.click();
    },
  },
};
</script>
<style lang="scss" scoped>
.input_info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 300px;
  margin: 10px 0 10px 10px;
}

.input_text {
  width: 100px;
  color: #889aa4;
}

.footer {
  margin: 100px;
  text-align: center;
}

.addAdmin {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 50px;

  &_CharacterSet {
    font-size: 15px;
    width: 120px;
    text-align: right;
  }

  &_select {
    flex: 1;
  }
}

.table_style {
  margin-left: 10px;
}

.dialog-footer {
  text-align: center;
}
</style>
